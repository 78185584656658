import React from 'react';
import styled from 'styled-components';

import {
  Caption,
  Container,
  FlexWrapper,
  H1,
  Regular,
  Seo,
} from '../../components';
import { background } from '../../styles/colors';
import { mobile } from '../../styles/breakpoints';

const Contacts: React.FC = React.memo(() => (
  <>
    <Seo title="Contacts | Boilerplate" />
    <H1 margin="3rem 0" textAlign="center">
      Contacts
    </H1>
    <Background>
      <Container>
        <FlexWrapperStyled justifyContent="space-between">
          <div>
            <Caption fontWeight={700}>Company</Caption>
            <Regular margin="0 0 1rem 0">DoFasting.com</Regular>
            <Caption fontWeight={700}>Company code</Caption>
            <Regular margin="0 0 1rem 0">303157579</Regular>
            <Caption fontWeight={700}>Address of registration</Caption>
            <Regular margin="0 0 1rem 0">
              Ramybės g. 4-70, Vilnius. Lithuania. European Union
            </Regular>
          </div>
          <div>
            <Caption fontWeight={700}>Address</Caption>
            <Regular margin="0 0 1rem 0">
              Antakalnio g. 17, Vilnius. Lithuania. European Union
            </Regular>
            <Caption fontWeight={700}>Email</Caption>
            <Regular margin="0 0 1rem 0">hello@homechallange.fit</Regular>
          </div>
        </FlexWrapperStyled>
      </Container>
    </Background>
  </>
));

Contacts.displayName = 'Contacts';

export default Contacts;

const Background = styled.div`
  padding: 3.5rem;
  min-height: calc(100vh - 16rem);
  background: ${background};
`;

const FlexWrapperStyled = styled(FlexWrapper)`
  @media ${mobile} {
    display: block;
  }
`;
